import styled from "styled-components"

import type { IProduct } from "@/types/common"

import { media } from "@/utils/styling"

import Container from "@/components/Container"
import ProductCard from "@/components/ProductCard"
import { H1 } from "@/components/Typo"

interface IProps {
  products: IProduct[]
}

export default function Products({ products }: IProps) {
  return (
    <Section>
      <Container>
        <Headline>
          Carefully crafted Design Resources for Mobile & Web Products
        </Headline>
        <Layout>
          {Object.entries(products)
            .reverse()
            .map(([id, product]) => (
              <ProductCard key={id} {...product} />
            ))}
        </Layout>
      </Container>
    </Section>
  )
}

const Section = styled.section`
  padding: 96px 0 148px;

  ${media.xs} {
    padding: 40px 0 48px;
  }
`

const Headline = styled(H1)``

const Layout = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  margin-top: 80px;

  ${media.sm} {
    gap: 24px;
    grid-template-columns: 1fr;
    margin-top: 24px;
  }
`
