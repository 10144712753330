import styled from "styled-components"

import { media } from "@/utils/styling"

import Logo from "@/media/Logo"

import Container from "./Container"
import { baseTextStyle } from "./Typo"

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <Layout>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Links>
            <LinkItem>
              <LinkAnchor href="https://fintory.com">Fintory.com</LinkAnchor>
            </LinkItem>
            <LinkItem>
              <LinkAnchor href="https://fintory.com/en/imprint">
                Imprint
              </LinkAnchor>
            </LinkItem>
          </Links>
        </Layout>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding: 40px 0;
  background: ${({ theme }) => theme.foreground};
`

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.xs} {
    flex-direction: column;
  }
`

const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.background};
`

const Links = styled.ul`
  display: flex;
  align-items: center;

  ${media.xs} {
    margin-top: 16px;
  }
`

const LinkItem = styled.li`
  &:not(:last-child) {
    margin-right: 60px;

    ${media.xs} {
      margin-right: 32px;
    }
  }
`

const LinkAnchor = styled.a`
  ${baseTextStyle}
  font-size: 13px;
  line-height: 28px;
  color: ${({ theme }) => theme.highContrast};

  &:hover {
    color: ${({ theme }) => theme.moderateContrast};
  }
`
