import type { NextPage } from "next"

import Navbar from "@/components/Navbar"
import PageLayout from "@/components/PageLayout"
import Footer from "@/components/Footer"

import Products from "@/sections/home/Products"
import { Seo } from "@/components/Seo"

const Home: NextPage<any> = ({ products }) => {
  return (
    <>
      <Seo title="All products" image="/meta.png" />
      <Navbar />
      <PageLayout>
        <Products {...{ products }} />
      </PageLayout>
      <Footer />
    </>
  )
}

export const getStaticProps = async () => {
  const products = await import("@/data/products").then(mod => mod.default)

  return {
    props: {
      products
    }
  }
}

export default Home
