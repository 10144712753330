import styled, { css } from "styled-components"

export const baseButtonStyle = css`
  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.foreground};
  display: inline-flex;
  align-items: center;
  height: 48px;
  padding: 0 20px;
  border: 1px solid ${({ theme }) => theme.foreground};
  background: transparent;
`

const Button = styled.button`
  outline: none;
  ${baseButtonStyle}
`

export default Button
