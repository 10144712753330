import Link from "next/link"
import Image from "next/image"
import styled from "styled-components"

import type { IProduct } from "@/types/common"

import { media, rgba } from "@/utils/styling"

import Button from "./Button"
import { H2, P } from "./Typo"

interface IProps extends IProduct {}

export default function ProductCard({
  slug,
  title,
  excerpt,
  featuredImage
}: IProps) {
  const href = `/products/${slug}`

  return (
    <Link href={href} passHref>
      <Wrapper>
        <ImageContainer>
          <ImageWrapper>
            <Image
              priority
              layout="fill"
              src={featuredImage}
              objectFit="cover"
              alt=""
            />
          </ImageWrapper>
        </ImageContainer>
        <Details>
          <div>
            <Title>{title}</Title>
            <Desc>{excerpt}</Desc>
          </div>
          <div>
            <StyledButton as="div">More Information</StyledButton>
          </div>
        </Details>
      </Wrapper>
    </Link>
  )
}

const ImageWrapper = styled.div`
  transition: transform ease 0.4s;
  transform: scale(1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const Wrapper = styled.a`
  background: #f5f6f6;

  &:hover {
    ${ImageWrapper} {
      transform: scale(1.04);
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  :before {
    content: "";
    display: block;
    padding-bottom: 75.55%;
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 32px 32px;

  ${media.sm} {
    padding: 24px 16px 16px;
  }
`

const Title = styled(H2)`
  ${media.xs} {
    font-size: 24px;
  }
`

const Desc = styled(P)`
  margin-top: 16px;

  ${media.xs} {
    font-size: 12px;
    margin-top: 4px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 32px;

  ${media.xs} {
    height: 32px;
    font-size: 12px;
    padding: 0 14px;
    margin-top: 24px;
  }
`
